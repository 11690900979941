.footer-bottom {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #000000;
}

.footer-top h5 {
    color: #a5a5a5;
}

.footer-top h5 span {
    color: #10CDF2; /* Update to blue color */
}

.footer-top {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;  
    background-color: #0f0f0f;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.footer-bottom p {
    color: #F4EFEF;
    margin: 0;
    padding: 15px 0px;
    font-family: poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;

}
.foooter-link a {
    font-size: 14px;
    font-weight: 400;
    font-family: poppins;
    color: #fff;
    text-decoration: none;
    position: relative;
    padding: 0px 20px 
}
.foooter-link a:after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #fff;
    opacity: 0.4;
}

.foooter-link a:last-child:after{
    display: none;
    padding-right: 0;
}
.support-text h6{
    font-size: 14px;
    font-weight: 400;
    font-family: poppins;
    color: #fff;
}
.support-text h6 a{
color: #10CDF2;
    text-decoration: none;
}
.footer-top{
    padding: 0px 100px;
}


@media only screen and (max-width: 1366px){
    .footer-top {
        padding: 0px 40px;
    }
    .foooter-link a {
        font-size: 13px;
        padding: 0px 14px;
    }
}

@media only screen and (max-width: 1023px){
    .foooter-link{
        justify-content: center;
    }
}

@media only screen and (max-width: 767px){
    .footer-top {
        padding: 0px 10px;
    }
    .foooter-link a {
        width: 33%;
    }
}
