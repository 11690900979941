@import '../../assets/css/variable/variable';

.sidebar_main{
    .offcanvas {
        border: none;
        background: #3F3E3E;
        box-shadow: inset -6px 0px 14px 3px rgba(0, 0, 0, 0.02);
        .offcanvas_header{
            padding: 20px;
            border-bottom: 1px solid #575555;
            .header_logo {
                img{
                    width: 88px;
                    height: 88px;
                    border-radius: 50%;
                    margin: auto;
                    display: block;
                }
                .logo_txt{
                    font-family: Poppins;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 33px;
                    color: #FFFFFF;
                    margin-top: 10px;
                    display: block;

                }
            }
        }
        .offcanvas-body{
            .inner_sidebar{
                .menu_list{
                    padding-top: 30px;
                    
                    nav{
                        overflow-x: hidden;
                        overflow-y: hidden;
                        position: relative;
                        // height: 392px;
                        // padding-bottom: 150px;
                    &::-webkit-scrollbar {
                        width: 8px;
                      }
                      &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px grey; 
                        border-radius: 10px;
                      }
                        &::-webkit-scrollbar-thumb {
                            background: #494949; 
                            border-radius: 10px;
                        }
                        
                        &::-webkit-scrollbar-thumb:hover {
                            background: #4b4b4b; 
                        }
                        .MuiListItem-root{
                            .MuiButtonBase-root {
                                padding: 10px;
                                .menu-name{
                                    font-family: Poppins;
                                    font-size: 18px;
                                    font-weight: 500;
                                    line-height: 27px;
                                    color: #fff;
                                }
                                .menu-icon{
                                    min-width: 48px;
                                    color: #fff;
                                }
                            }
                            
                            &.active{
                                background-color: #10CDF2;
                                .MuiButtonBase-root {
                                    .menu-name, .menu-icon{
                                        color: #000;
                                    }
                                }
                               
                            }
                            // &:last-child{
                            //     // position: fixed;
                            //     // bottom: 0;
                            //     background-color: #3f3e3e;
                            // }
                        }
                        .top-menu{
                            overflow-y: auto ;
                            padding-bottom: 60px;
                        }
                        .bottom-menu{
                            position: fixed;
                            bottom: 0;
                            width: 250px;
                            padding: 0px;
                        }
                    }
                }
              
            }
        }
    }
}

// Offcavas common css
.offcanvas {
    visibility: visible;
    &.offcanvas-start {
        left: 250px;
        width: 250px;
        z-index: 99999;
        // .offcanvas-header {
        //     //   padding: 15px 0 0 0;
        //     padding: 0;
        //     justify-content: flex-end;
        //     .close{
        //         background-color: #0B4977;
        //         border: none;
        //         border-radius: unset;
        //         opacity: 1;
        //         margin: 0 -1px 0 0;
        //         padding: 0;
        //         height: 30px;
        //         width: 30px;
        //         line-height: 30px;
        //     }
        // }
        .offcanvas-body {
            overflow: hidden;
            padding: 0;
        }
    }
}
