.loader {
  width: 90px !important;
  height: 90px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #05a3c1 !important;
}

.error-text {
  font-size: 25px;
  align-items: center;
  margin-top: 15px;
  font-weight: 500;
  font-family: Poppins;
}

.returns-page {
  #simple-tabpanel-0 {
    .MuiTable-root {
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            &:nth-child(2) {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0em;
              color: #10cdf2;
            }

            &:nth-child(3) {
              font-family: Poppins;
              font-size: 14px;
              font-weight: 400;
              line-height: 30px;
              letter-spacing: 0em;
              color: #000;
            }
          }
        }
      }
    }
  }

  #simple-tabpanel-1 {
    .MuiTable-root {
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            &:nth-child(2) {
              color: #000;
              font-weight: 400;
            }

            &:nth-child(3) {
              color: #10cdf2;
            }

            &:nth-child(4) {
              color: #000;
              font-weight: 400;
            }

            &:nth-child(6) {
              font-weight: 400;
            }

            &:nth-child(7) {
              font-weight: 600;
              color: #000;
            }
          }
        }
      }
    }
  }

  #simple-tabpanel-2 {
    .MuiTable-root {
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding: 16px 0px;
            &:nth-child(3) {
              color: #10cdf2;
            }

            &:nth-child(2) {
              color: #000;
              font-weight: 400;
            }

            &:nth-child(6) {
              font-weight: 400;
            }

            &:nth-child(7) {
              font-weight: 600;
              color: #000;
            }
          }
        }
      }
    }
  }

  .mobile-table-block
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root:nth-child(2)
    td {
    font-size: 14px;
    font-weight: 400;
    color: #10cdf2;
    font-family: Poppins;
  }

  .mobile-table-block
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root:nth-child(3)
    td {
    color: #000;
  }

  .mobile-table-block
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root:nth-child(6)
    td {
    font-weight: 600;
  }

  .mobile-table-block
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root:nth-child(7)
    td {
    font-weight: 600;
    color: #000;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .returns-page {
    .returns-page {
      .no-product-found {
        display: none;
      }
    }
  }

  .mobile__view__none {
    display: none;
  }

  .mobile__view__block {
    display: block;
  }

  .returns-page {
    .deskPagination {
      display: none;
    }
  }

  .returns-page
    #simple-tabpanel-2
    .MuiTable-root
    .MuiTableBody-root
    .MuiTableRow-root
    .MuiTableCell-root {
    padding: 16px 12px;
  }
}
