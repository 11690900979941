.password-reset-main {
    margin : auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1024px;
    height: auto;
    background-color: #FAF9F9;
}


.password-reset-top-section .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.password-reset-top-section .title-line {
    width: 63px;
    border: 1px solid #10CDF2;
    border-image-source: linear-gradient(0deg, #10CDF2, #10CDF2),
        linear-gradient(0deg, #10CDF2, #10CDF2);
}

.password-reset-bottom-section {
    width: 95%;
    height: auto;
    border: 1px solid #DEDCDC;
    background-color: white;
    margin-bottom: 30px;
}

.password-reset-bottom-section{

  .form-title-box .form-title {
    margin-left: 41px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.form-line {
    width: 100%;
    border: 1px solid #DEDCDC;
    color: #DEDCDC;
    ;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form-detail {
    margin-bottom: 50px;
}


.form-detail .input-title-wrapper {
    margin-bottom: 12px;
}

.form-input-title-box {
    margin-left: 25px;
}

.form-input-title {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}


.submit-btn-box {
    width: 90%;
    margin-bottom: 20px;
}

.submit-btn-box .submit-btn {
    width: 100%;
    height: 64px;
    background: #10CDF2 !important;
    margin-bottom: 20px;
}

.submit-btn-text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: capitalize;
}
}