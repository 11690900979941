
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html, body{
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}
.mobile-menu-block{
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
}
.main-top-bar{
    background-color: #232F3E !important;
    padding: 10px 0px;
    position: relative !important;
}
.navbar-menu-block{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.top-bar{
    display: flex;
    flex-direction: column;
}
/* .top-bar-login{
    margin-left: auto;
} */
.top-bar-login .form-field-section .form-group{
    display: flex;
    flex-direction: column;
}
.top-bar-login .form-field-section{
    display: flex;
    align-items: center;
    gap: 15px;
}
.top-bar-login .form-field-section .form-group .form-group-input{
    border-radius: 4px;
    min-width: 176px;
    height: 48px;
    outline: 0;
    box-shadow: none;
    border: 1px solid #ddd;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.top-bar-login .form-field-section .form-group .form-group-input:focus{
    outline: 0;
    box-shadow: none;
}
.top-bar-login .form-field-section .form-group .form-group-input::placeholder{
    font-size: 14px;
    font-weight: 400;
    color: #ACA5A5;
    font-family: "Poppins", sans-serif;
}
.top-bar-login .form-field-section .form-group .forgot-password-link{
    color: #fff;
    text-decoration: none;
    margin-bottom: 3px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.top-bar-login .form-field-section .form-group .login-btn{
    background-color: #10CDF2;
    height: 48px;
    min-width: 176px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
}
.top-bar-login .form-field-section .form-group label{
    margin-bottom: 5px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
}
.form-field-section .register-btn-block{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}
.form-field-section .register-btn-block .login-btn{
    min-width: 90px;
    height: 32px;
    background-color: #10CDF2;
    /* margin-top: 15px; */
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
}
.MuiDrawer-modal .MuiDrawer-paper{
    top: 110px;
    background-color: #3F3E3E;
    max-height: 315px;
}
.navbar-menu-block .navbar-menulist{
    width: 100%;
    color: #fff;
}
.navbar-menu-block .navbar-menulist .MuiListItem-root .MuiListItemButton-root:hover{
    background-color: #fff;
    color: #3F3E3E;
    margin: 0px 10px;
}
@media (min-width: 320px) and (max-width:375px){
    .main-top-toolbar{
        flex-wrap: wrap;
    }
    .top-bar{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .top-bar-login{
        display: flex;
        justify-content: center;
        margin-left: 0;
        width: 100%;
    }
    /* .form-field-section .register-btn-block{
        justify-content: center;
    } */
}
@media (min-width: 320px) and (max-width:767px){
    .main-top-toolbar{
        flex-wrap: wrap;
    }
    .top-bar{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width:899px){
    .main-top-toolbar{
        flex-wrap: wrap;
    }
    .top-bar{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
}

@media (max-width: 320px) {
    .form-field-section .register-btn-block {
      gap: 8px; 
    }

    .form-field-section .register-btn-block .login-btn{
        min-width: 70px;
        height: 32px;
        background-color: #10CDF2;
        /* margin-top: 15px; */
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        text-transform: capitalize;
    }
  }