@import '../../assets/css/variable/variable';


.header{
    padding: 10px 20px;
    left: 250px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    background-color: #FAF9F9;
    // border-bottom: 1px solid #ddd;
    .header_inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left_part{
            display: flex;
            align-items: center;
            .btn_close{
                padding: 0;
                border: none;
                width: 24px;
                height: 22px;
                min-width: 24px;
                margin: 0 10px 0 0;
                background-color: transparent;
            }
            .topbar-btn {
                width: 185px;
                height: 44px ;
                background-color: #3F3E3E;
                border-radius: unset;
            }
            .topbar-btn-text {
                font-family: Poppins;
                font-size: 19px;
                font-weight: 700;
                line-height: 38px;
                letter-spacing: 0em;
                color: #FFFFFF;
                text-transform: capitalize;
            }
            .main-titles{
                font-family: Poppins;
                font-size: 27px;
                font-weight: 700;
                line-height: 45px;
                letter-spacing: 0em;
                text-align: left;
                color: #3F3E3E;

            }
        }
        .right_part{
            display: flex;
            align-items: center;
            .right-sidebar {
                display: flex;
                flex-direction: row;
                gap: 17px;
                justify-content: flex-end;
                .country_flag{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .icons {
                    width: 48px;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    border: 1px solid #9A9797;
                    border-radius: 50%;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        width: 14px;
                        height: 14px;
                        line-height: 14px;
                        text-align: center;
                        background-color: #F40B0B;
                        font-family: Poppins;
                        font-size: 9px;
                        font-weight: 700;
                        letter-spacing: 0em;
                        color: #fff;
                        display: inline-block;
                        border-radius: 50%;
                        position: absolute;
                        top: 8px;
                        right: 6px;
                    }

                    svg {
                        width: 30px;
                        height: 30px;
                    }

                }
            }
        }
    }
}

@media screen and (min-width:$desktop_pro_up) {
    .header{
        .header_inner{
            .right_part{
                .common_content{
                    &.user {
                        .user_inner{
                            .profile_img{
                                margin-right: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// @media screen and (max-width:$tablet) {
//     header{
//         &.header{   
//             padding: 12px;
//             left: 0;
//             margin: 0;
//             background:#ffffff;
//             border-bottom: none;
//         }
//     }
// }


@import '../../assets/css/responsive/responsive';

