.payment-page {
    #simple-tabpanel-0{
        .MuiTable-root {
            .MuiTableBody-root {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        &:nth-child(6) {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: #FD1111;
                        }
                    }
                }
            }
        }
    }
    #simple-tabpanel-1{
        .MuiTable-root {
            .MuiTableBody-root {
                .MuiTableRow-root {
                    .MuiTableCell-root {
                        &:nth-child(6) {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 300;
                            line-height: 30px;
                            letter-spacing: 0em;
                            color: #2586FF;
                        }
                    }
                }
            }
        }
    }
    .title.table-title{
        margin-bottom: 10px;
        h5{
            color: #373737;
            font-weight: 600;
            line-height: 37px;
        }
    }
    .mobile__view__block{
           display: none;
    }
    .no-data-available{
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: center;
        color: #3F3E3E;
    }
}

@media (min-width:320px) and (max-width:767px){
    .mobile__view__none{
        display: none;
    }
    .mobile__view__block{
        display: block !important;
        .title.table-title.m-view-title{
            margin: 10px 0px;
            display: block;
            h5{
                color: #373737;
                font-weight: 600;
                line-height: 37px;
            }
        }
    }
}