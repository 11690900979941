
// colors
  $bodycolor:#495057;
  $bodyhovercolor:#3980c0;
  $bodybackground:#f4f5f6;
  $bodyopacitycolor:rgba(55, 27, 43, 0.8);
  $bodysecondarycolor:#3980c0;
  $labelcolor:$bodyopacitycolor;
  $formfiledbackgroundcolor:#FBFBFB;
  $formfiledbordercolor: rgba(0, 0, 0, 0.12);
  $formfiled_color:$bodycolor;
  $white_color: #ffffff;
  $black_color:#000000;
  $linkcolor: #3980c0;
  $linkhovercolor: $bodycolor;
  $successcolor:#299D00;



$sidebarbackgroundcolor:#003032;
$sidebarcolor:#a8b0c5;
$common_border:#B6C8D6;

$star_color: #E8A908;
$homepage_hovercolor:#3DA7C8;


// sidebar colors
$sidebar_background: #F6F8FC;
$sidebar_font_color: #0B4977;

// header colors
$header_background: #ffffff;
$header_icon_color: #26323C;


// Counter background
$light_green:#E1F3EA;
$icon_light_green:rgba(146, 201, 173, 0.5);
$counter_no_green:#1C5A3A;

$light_yellow:#FFF2E6;
$icon_light_yellow:rgba(233, 196, 161, 0.5);
$counter_no_yellow:#D97519;















