.orders-tabs{
    .MuiGrid-root > .MuiGrid-item {
        padding-top: 0px !important; 
      }

    .order-tabs-head{
        .tabs-title{
            font-size: 17px;
            font-weight: 600;
            color: #C1BEBE;
            line-height: 30px;
            text-transform: capitalize;
            padding: 3px 16px;
            min-height: 49px;
            &.Mui-selected{
                color: #05A3C1;
            }

        }
        .MuiTabs-indicator{
            background-color: #05A3C1;
        }
    }
}

.order-filter-section{
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    .select-form {
        min-width: 100px;
        border: 1px solid #D8D4D4;
        border-radius: 7px;
        .MuiSelect-select{
            color: #312F2F;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            font-family: Poppins;
            padding: 8px 2px 8px 15px;
            text-transform: capitalize;
            
        }
        .MuiOutlinedInput-notchedOutline{
            border: 0px;
        }
    }
}

.loader {
    width: 90px !important;
    height: 90px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #05A3C1 !important;
}

.error-text{
    font-size: 18px;
    align-items: center;
    margin-top: 15px;
    font-weight: 500;
    font-family: Poppins;
}


// mobile tabs menu 
.mobile-tabs-menu{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F1EEEE;
    color: #312F2F;
    padding: 12px 15px;
    position: relative;
    display: none;
    .active-tabs-text{
        color: #3F3E3E;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
    }
    .mobile-menu-list{
        position: absolute;
        top: 52px;
        left: 0px;
        background-color: #3F3E3E;
        width: 100%;
        z-index: 999;
        .list-wrap{
            padding: 15px 0px;
            margin: 0px 10px;
            .listmenu-items{
                
                .listmenu-text{
                    color: #fff;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 27px;
                }
                &:hover{
                    background-color: #fff;
                    transition: 0.3s;
                    .listmenu-text{
                        color: #312F2F;
                    }
                }
                &.active{
                    background-color: #fff;
                    transition: 0.3s;
                    .listmenu-text{
                        color: #312F2F;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 27px;
                        span{
                            color: #312F2F;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 27px; 
                        }
                    }
                }
            }
        }
    }
}

.main{
    .btn-group{
        margin-top: 1px;
        #search_product {
            width: 300px !important;
            height: 45px !important;
            font-size: 16px;  
            border: 1px solid #ececec; 
            border-radius: 0px; 
            outline: none; 
            box-sizing: border-box;
            font-family: Poppins; 
            
            &:focus {
              border-color: #007bff; 
            }
          }
          
        .search-button{
           background-color: #ddd;
            border-radius: unset;
            color: #000;
            width: 132px;
            height: 45px;
            &:hover {
                background-color: "#bbb",
              }
        }
        .btn-text {
            font-family: Poppins;
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
            text-transform: capitalize;
        }
    }
}

@media (min-width:320px) and (max-width:767px){
    .mobile-tabs-menu{
        display: flex;
    }
    .filter-tabs-block{
        display: none !important;
    }
}
@media (min-width:768px){
    .mobile-wrapper{
        .MuiGrid-root{
            display: none;
        }
    }
  
}


@media (max-width:767px){
    .orders-tabs{
        .main{
            .deskPagination{
                display: none;
            }
        }
    }
}