// maniform css start
.seller-acc-main {
    padding: 20px;
    width: 60%;
    // text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAF9F9;
    gap: 20px;
    margin: 20px auto;

    h1 {
        font-family: Poppins;
        font-size: 25px;
        font-weight: 700;
        line-height: 38px;
    }

    .main-box {
        width: 100%;

        .title {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            padding-left: 15px;
        }

        .company-upper {
            row-gap: 15px;

            label {
                font-family: poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                display: block;
                margin-bottom: 12px;
                width: 100%;
                &.vat-title-label{
                    min-height: 48px;
                }

                &::after {
                    content: ' *';
                    color: #FF0000;
                }

                &.unastrik {
                    &:after {
                        content: '';
                    }
                }
            }

            .input-field {
                width: 100%;

                .MuiInputBase-root {
                    border-radius: 0px;
                }

                input {
                    width: 100%;
                    font-family: poppins;
                }

            }

            h6 {
                font-family: poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
            }

            .password-msg {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
                margin-top: 10px;
            }

            .list-items {
                li {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0em;
                    text-align: left;
                }

            }

        }

        .checkbox-text {
            font-family: poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;

            a {
                color: #10CDF2;
                text-decoration: none;

            }
        }

        .forgotPwdText {
            cursor: pointer;
            color: #10CDF2;
        }

    }

    .create-btn {
        font-family: Poppins;
        font-size: 21px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        background-color: #10CDF2;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        width: 100%;
        text-transform: capitalize;

        &:hover {
            background-color: #10CDF2;
        }

    }
}

body.ar{
    .directionrtl {
        direction: rtl !important;
        text-align: right;
        // text-align:left;
    }
    .seller-acc-main {
        .main-box {
            .company-upper {
                label {
                    text-align: right;
                }

                .list-items{
                    li{
                        text-align: right;
                    }
                }
            }
        }
    }

    .selling-section{
        .checkbox-wrapper{
            flex-direction: row-reverse;
        }
    } 

    .top-bar-login {
        margin-right: auto !important;
    }
    .seller-acc-main{
        .main-box{
            .company-upper{
                .password-msg{
                    text-align:right
                }
            }
        }
    } 

    .referral-main{
        .bottom-section{
            .table-title-name {
                text-align: right;
           }
        }
    }  

    .referral-main{
        .bottom-section{
            .table-content-name {
                text-align: right;
            }
        }
    } 
    
    .MuiPagination-root .MuiPagination-ul li .MuiPaginationItem-previousNext {
        rotate: 360deg;
    }
}

@media only screen and (max-width: 1366px) {
    .seller-acc-main {
        width: 75%;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .seller-acc-main h1 {
        font-size: 19px;
        line-height: 23px;
    }

    .company-upper>.MuiGrid-item {
        // max-width: 50%;
    }

    .seller-acc-main .main-box .company-upper .input-field input {
        padding: 12px 14px;
        font-size: 14px;
    }

    .seller-acc-main .main-box .company-upper label {
        font-size: 15px;
        // line-height: 19px;
    }
    .seller-acc-main .main-box .company-upper label.vat-title-label{
        // line-height: normal;
    }
    .seller-acc-main .main-box .company-upper .password-msg {
        font-size: 14px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .seller-acc-main h1 {
        font-size: 16px;
        line-height: 20px;
    }

    .seller-acc-main {
        width: 94%;
        padding: 20px 0px;
        gap: 10px;
    }

    .seller-acc-main .main-box .title {
        font-size: 14px;
        line-height: 21px;
        padding-left: 0;
    }

    .seller-acc-main .main-box .company-upper {
        padding: 30px 20px;
        gap: 10px;
    }

    .seller-acc-main .main-box .company-upper label {
        font-size: 14px;
        line-height: 19px;
    }

    .seller-acc-main .main-box .company-upper .input-field input {
        padding: 12px 14px;
        font-size: 14px;
    }

    .seller-acc-main .main-box .company-upper h6 {
        font-size: 14px;
        line-height: 22px;
    }

    .seller-acc-main .create-btn {
        font-size: 15px;
        line-height: 32px;
    }
}

// mainform css end

// referralfree css start
.referral-main {
    text-align: center;

    .bottom-section {
        width: 800px;

        .table-title-name {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;

            &:not(:last-child) {
                border-right: 1px solid #E5E4E4;
            }
        }

        .table-content-name {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #000;

            &:not(:last-child) {
                border-right: 1px solid #E5E4E4;
            }
        }
    }
}

.main-head {
    .title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 40px;

        h1 {
            font-family: Poppins;
            font-size: 35px;
            font-weight: 700;
            line-height: 53px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0;
        }

        .title-line {
            width: 63px;
            border: 1px solid #10CDF2;
        }
    }
}

.center-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
    .seller-acc-main .main-box .company-upper label.vat-title-label{
        min-height: auto;
    }
}
@media only screen and (max-width: 1023px) {
    .referral-main .bottom-section {
        width: 650px;
    }

    .main-head .title h1 {
        font-size: 24px;
        line-height: 27px;
    }

    .main-head .title {
        margin-bottom: 27px;
    }
    .seller-acc-main .main-box .company-upper label.vat-title-label{
        min-height: auto;
    }
    .seller-acc-main .main-box .company-upper .input-field.file-input input{
        padding: 16.5px 14px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .referral-main .bottom-section {
        width: 350px;
    }

    .referral-main .bottom-section .table-title-name {
        font-size: 16px;
    }

    .referral-main .bottom-section .table-content-name {
        font-size: 15px;
        line-height: 22px;
    }

    .main-head .title .title-line {
        width: 43px;
    }
}

@media only screen and (max-width: 320px) {
    .referral-main .bottom-section {
        width: 300px;
    }

    .main-head .title h1 {
        font-size: 19px;
    }
}

// referralfree css end

// faq css start

.faq-bottom-section {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

// faq css end

// advertise css start
.advertise-main {
    margin: 20px auto 40px;

    .main-head {
        .title {
            margin-bottom: 10px;
        }
    }

    .main-box {
        .company-upper label {
            &::after {
                display: none;
            }
        }
    }
}

// advertise css end

// home css start
.benefit-section {
    padding: 30px 24px;

    .benefit-grid {
        row-gap: 15px;
        justify-content: center;
    }

    .benefit-card {
        border: 1px solid #CDCACA;
        box-shadow: unset;
        height: 100%;

        img {
            width: 100%;
            height: 290px;
            object-fit: cover;
        }

        h5 {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 500;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: center;
            color: #000;
            margin: 0;
        }

        .MuiCardContent-root {
            padding: 30px;
        }

        .MuiCardActionArea-root {
            &:hover {
                .MuiCardActionArea-focusHighlight {
                    display: none;
                }
            }
        }
    }

}

.selling-section {
    text-align: center;
    padding: 50px 20px;
    background-color: rgb(10, 73, 133);
    background-image: url("../src/assets/images/selling-background.png");
    background-size: cover;
    background-position: center;

    .selling-text-section {
        margin-bottom: 30px;

        h1 {
            text-transform: uppercase;
            font-family: Poppins;
            font-size: 32px;
            font-weight: 700;
            line-height: 53px;
            letter-spacing: 0em;
            text-align: center;
            color: #FFFFFF;
            margin: 0;
        }
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: row;
        gap: 40px;
        justify-content: center;
        align-items: center;


        .checkbox-text {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 7px;


            h5 {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                line-height: 33px;
                letter-spacing: 0em;
                color: #FFFFFF;
                margin: 0;


            }
        }
    }

}

.bottom-section {
    padding-top: 50px;

    .text-section {
        margin-bottom: 50px;

        h2 {
            font-family: Poppins;

            font-size: 32px;
            font-weight: 700;
            line-height: 53px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0;
            color: #3F3E3E;
        }

        h5 {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            margin: 0;
            color: #3F3E3E;
        }
    }

    .image-section {
        text-align: center;

        img {
            width: 80%;
            height: auto;
        }
    }
}
.bottom-section-referral{
    padding-top: 0;
}

@media only screen and (max-width: 1600px) {
    .benefit-section .benefit-card img {
        height: 240px;
    }

    .benefit-section .benefit-card h5 {
        font-size: 19px;
        line-height: 31px;
    }
}

@media only screen and (max-width: 767px) {
    .benefit-section .benefit-card img {
        height: 120px;
    }

    .benefit-section .benefit-card h5 {
        font-size: 14px;
        line-height: 25px;
    }

    .benefit-section .benefit-card .MuiCardContent-root {
        padding: 13px;
    }

    .benefit-section .benefit-grid {
        row-gap: 0px;
    }

    .selling-section .selling-text-section h1 {
        font-size: 24px;
        line-height: 41px;
    }

    .selling-section .checkbox-wrapper {
        gap: 15px 0px;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .selling-section .checkbox-wrapper .checkbox-text {
        justify-content: flex-start;
        width: 50%;
    }

    .selling-section .checkbox-wrapper .checkbox-text h5 {
        font-size: 15.5px;
    }

    .selling-section .selling-text-section {
        margin-bottom: 20px;
    }

    .bottom-section .text-section h2 {
        font-size: 22px;
    }

    .bottom-section .text-section h5 {
        font-size: 15px;
    }
}

//sidebar css start
.sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 900px;
    background-color: #3F3E3E;
    padding: 10px;

    .text {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
    }

    .upper-section {
        width: 100%;

        .logo-section {
            margin: 10px;
            text-align: center;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
            }

            .logo-text {
                font-family: Poppins;
                font-size: 22px;
                font-weight: 700;
                line-height: 33px;
                letter-spacing: 0em;
                color: #FFFFFF;

            }
        }

        .divider {
            width: 100%;
            border: 1px solid #575555;
            margin: 10px 0;
        }

        .nav-section {

            .navlink-name {
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: flex-start;
                align-items: center;
                padding: 10px 15px;
                cursor: pointer;

                &:hover {
                    background-color: #555;
                }
            }
        }

    }

    .logout-section {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 21px;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
            background-color: #555;
        }
    }
}


.capture-block {
    display: flex;
    align-items: center;
    gap: 50px;

    .capture-img {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
        background-image: url(https://www.optacom.com/components/com_breezingforms/images/captcha/backgrounds/bg6.png);
        background-size: 'cover';
        background-position: 'center';
        width: '100%';
        height: '200px';
        min-width: 221px;
        height: 62px;
        border: 1px solid #ccc;
        max-width: 200px;
    }

    .create-ac-btn {
        min-width: 217px;
        height: 62px;
        background-color: #000;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        max-width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        cursor: pointer;
    }
}

@media(min-width:320px) and (max-width:600px) {
    .capture-block {
        gap: 15px;
    }

    .capture-block .capture-img {
        min-width: 120px;
    }

    .capture-block .create-ac-btn {
        min-width: 120px;
        font-size: 14px;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .forgot-block .company-upper .MuiGrid-item {
        max-width: 100%;
    }
}