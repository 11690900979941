// @import '../variable/breakpoints';


// @media screen and (min-width:$desktop_medium_up) {
//     .container{
//         max-width: 1240px;
//     }
// }
// @media screen and (min-width:$desktop_large_up) {
//     .container{
//         max-width: 1320px;
//     }
// }


@media screen and (max-width:1023px) {
    .container{
        max-width: none;
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
        padding: 0;
    }
    header.header{   
        padding: 12px;
        left: 0px;
        margin: 0;
        background:#EFEAEA;
        border-bottom: none;
    }
    .sidebar_main  .offcanvas.offcanvas-start{
        left: 0px;
        z-index: 9999;
    }
    .header .header_inner {
        justify-content: flex-end;
        gap: 11px;
    }
    .header .header_inner .left_part {
        width: 100%;
        gap: 18px;
    }
    .header .header_inner .left_part .topbar-btn {
        width: 130px;
        height: 35px;
    }
    .header .header_inner .left_part .topbar-btn-text {
        font-size: 13px;
    }
    .header .header_inner .right_part .right-sidebar {
        gap: 9px;
    }
    .header .header_inner .right_part .right-sidebar .icons {
        width: 33px;
        height: 33px;
        line-height: 40px;
    }
    .header .header_inner .right_part .right-sidebar .icons svg {
        width: 22px;
        height: 22px;
    }
    .header .header_inner .right_part .right-sidebar .icons span {
        width: 12px;
        height: 12px;
        line-height: 12px;
        font-size: 8px;
        top: 9px;
        right: 6px;
    }
    .header .header_inner .left_part .main-titles {
        font-size: 22px;
    }

}
@media (min-width:992px) and (max-width:1023px){
    header.header{
        left: 250px;
    }
    .sidebar_main .offcanvas.offcanvas-start{
        left: 250px;
    }
}

@media (max-width: 425px) {
    .header .header_inner .left_part .main-titles {
        font-size: 16px;
    }

    .header .header_inner .left_part {
        width: 100%;
        gap: 5px;
    }

    .header .header_inner .left_part .btn_close {
        width: 20px;
        height: 22px;
    }

  }

  @media (max-width: 320px) {
    .header .header_inner .right_part .right-sidebar .icons {
        display:none;
    }
  }