.commmon-tables{
    .MuiTable-root{
        .MuiTableHead-root{
            background-color: #F3F3F5;
            .MuiTableRow-head{
                .MuiTableCell-head{
                    white-space: nowrap;
                    color: #3F3E3E;
                    padding:14px 8px;
                    font-weight: 500;
                    font-family: poppins;
                    text-align: center;
                    border-bottom: none; 
                    // border-right:  1px solid #D8D4D4;

                    // &:last-child {
                    //     border-right: none; 
                    //   }
                    
                    span{
                        color: #fff;
                        
                        svg{
                            color: #fff;
                        }
                    }
                }
            }
        }
        .MuiTableBody-root{
            .MuiTableRow-root{
                .MuiTableCell-root{
                    border-bottom: 1px solid #D8D4D4; 
                    border-right: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                    font-family: Poppins;
                    padding:8px;
                    text-align: center;
                    // border:none;

                    &:nth-child(1){
                        font-size: 14px;
                        font-weight: 400;
                        color: #000;
                        font-family: Poppins;
                    }
                    &:nth-child(2){
                        font-size: 14px;
                        font-weight: 500;
                        color: #000;
                        font-family: Poppins;
                    }
                    &:nth-child(3){
                        font-size: 14px;
                        font-weight: 500;
                        color: #10CDF2;
                        font-family: Poppins;
                    }
                    &:nth-child(7){
                        font-size: 14px;
                        font-weight: 500;
                    }
                    .custom-btn-0 {
                        background-color: #10CDF2;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        box-shadow: none;
                        border-radius: 0px;
                        text-transform: capitalize;
                        width: 70%;
                        // height:45px;

                        @media screen and (max-width: 1200px) {
                            width: 100%;    
                          }
                    }
                    
                    .custom-btn-1 {
                        background-color: #B2B6B7;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        box-shadow: none;
                        border-radius: 0px;
                        width: 70%;
                        // height:45px;

                        @media screen and (max-width: 1200px) {
                            width: 100%;    
                          }
                    }
                }
            }
        }
    }
    .paginations{
        p{
            margin-bottom: 0;
        }
    }
    #search_product {
        width: 300px !important;
        height: 45px !important;
        font-size: 16px;  
        border: 1px solid #ccc; 
        border-radius: 0px; 
        outline: none; 
        box-sizing: border-box;
        font-family: Poppins; 
        
        &:focus {
          border-color: #007bff; 
        }
      }
}