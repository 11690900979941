.main-div {
    background-color: #F1F1F1;
    padding: 12px 20px;
    .upper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        .btn-box {
            width: 32px;
            height: 32px;
            border: none;
            .icon-btn {
                color: #10CDF2;
                cursor: pointer;
            }
        }
        .title {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0em;
            text-align: left;
            margin: 0;
        }
    }
    .lower {
        padding-top: 10px;
        .detail-text {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            // text-align: left;
            color: #313030;
            margin: 0;
        }
    }
}

@media only screen and (max-width: 1023px){
    .faq-bottom-section {
        width: 80%;
    }
}

@media only screen and (max-width: 1023px){
    .faq-bottom-section {
        width: 90%;
    }
    .faq-main .main-head .title .title-line {
        display: none;
    }
    .main-div .lower .detail-text {
        font-size: 15px;
        line-height: 21px;
    }
    .main-div .upper .title {
        font-size: 17px;
    }
}

@media only screen and (max-width: 320px){
    .main-div .upper .title {
        font-size: 16px;
    }
    .main-div .lower .detail-text {
        font-size: 14px;
    }
    .main-div .lower {
        padding-top: 7px;
    }
}