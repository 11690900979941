.page-details {
    padding: 100px 20px;

    .top-card-wrapper {
        background-color: #FAF9F9;
        padding: 30px 30px 20px 15px;
        margin-bottom: 20px;

        .card-bg {
            background-color: #FFFFFF;
            border: 1px solid #F5F1F1;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .top {
                background-color: #10CDF2;
                padding: 10px 30px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                h5 {
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 21px;
                    color: #171717;
                }

                h6 {
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 27px;
                    color: #000000;

                }
            }

            .bottom {
                padding: 20px 30px;
                display: flex;
                flex-direction: column;
                row-gap: 25px;

                .bottom-text {
                    display: flex;
                    justify-content: space-between;

                    h3 {
                        font-family: Poppins;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #423F3F;
                        margin-bottom: 7px;

                    }
                }

                .top-selling-list {
                    display: flex;
                    border-bottom: 1px solid #D8D4D4;
                    padding-bottom: 11px;

                    h3 {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 21px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #3C3C3D;

                        a {
                            color: #10CDF2;
                            text-transform: capitalize;
                        }
                    }

                    .MuiTypography-root {
                        width: 50%;

                        &:last-child {
                            text-align: right;
                        }
                    }

                    h4 {
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #3F3E3E;

                    }
                    .seeAllBtn{
                        cursor: pointer;
                    }
                }

                .top-selling-list-item> :first-of-type {
                    color: #10CDF2;
                }


            }
        }

        &.graph-wrapper {
            background-color: #fff;
            padding: 0;

            // .graph-label{
            //     font-family: Poppins;
            //     font-size: 14px;
            //     font-weight: 700;
            //     line-height: 27px;
            //     color: #fff;
            // }
            .orders-card {
                &.card-bg {
                    display: flex;
                    align-items: center;
                    gap: 0px;
                    background-color: #FAF9F9;

                    .graph-label {
                        width: 40%;
                        background-color: #3F3E3E;
                        padding: 13px;
                        height: 100%;
                        text-align: center;

                        p {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 27px;
                            color: #fff;
                        }
                    }
                }
            }

            .bottom-map-block {
                background-color: #fff;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .right-side {
                width: 60%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 8px;
                gap: 10px;

                .MuiTypography-root {
                    font-size: 13px;
                    font-weight: 500;
                    font-family: Poppins;
                    line-height: 20px;
                    color: #000;
                    white-space: nowrap;

                    span {
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        line-height: 20px;
                        color: #058098;
                    }
                }

                .select-form {
                    width: 100px;

                    .MuiInputBase-root {
                        background-color: #fff;
                    }

                    .MuiSelect-select {
                        color: #757171;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        font-family: Poppins;
                        padding: 8px 2px;
                    }

                    .MuiOutlinedInput-notchedOutline {
                        border: 0px;
                    }
                }
            }

            .order-status-card {
                .header-section {
                    background-color: #FAF9F9;
                    height: 55px;
                }

                .order-status-section {
                    padding: 30px 15px;
                    max-height: 323px;
                    overflow-y: auto;

                    .status-wrapper {
                        margin-bottom: 20px;
                        display: flex;
                        align-items: center;

                        .svg-icon {
                            margin-right: 10px;
                            color: #10CDF2;
                        }

                        .status-text {
                            .status-title-text {
                                font-family: Poppins;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 21px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: #3F3E3E;
                            }

                            .time-text {
                                font-family: Poppins;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: #3F3E3E;
                            }
                        }
                    }
                }
            }
        }


    }

    .top-card-wrapper {
        &.right-side-maps-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 23px;
            padding: 15px;

            .orders-card {
                &.card-bg {
                    width: 100%;
                }
            }
        }
    }

    .bottom-card-wrapper {
        display: flex;
        gap: 20px;
        padding: 15px;

        .map-section {
            width: 50%;
            background-color: #FFFFFF;
            border: 1px solid #F5F1F1;

            .top-section {
                display: flex;
                flex-direction: row;
                background-color: #FAF9F9;
                justify-content: flex-start;
                align-items: center;

                .left-side {
                    text-align: center;
                    width: 50%;
                    background-color: #353434;
                    color: #FFFCFC;
                    height: 100%;
                    padding: 15px;

                    .MuiTypography-root {
                        font-size: 18px;
                        font-weight: 700;
                        font-family: Poppins;
                        line-height: 27px;

                    }
                }

                .right-side {
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0px 10px;

                    .MuiTypography-root {
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        line-height: 20px;
                        color: #000;

                        span {
                            font-size: 13px;
                            font-weight: 500;
                            font-family: Poppins;
                            line-height: 20px;
                            color: #058098;
                        }
                    }

                    .select-form {
                        width: 100px;

                        .MuiSelect-select {
                            color: #757171;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                            font-family: Poppins;
                            padding: 15px 0px;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border: 0px;
                        }
                    }
                }
            }
        }

        .order-status-card {
            width: 50%;
            background-color: #FFFFFF;
            border: 1px solid #F5F1F1;

            .order-status-section {
                .status-wrapper {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .svg-icon {
                        color: #10CDF2;
                    }
                }
            }
        }
    }

    .scrollbar-design::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #F0EDED;
    }

    .scrollbar-design::-webkit-scrollbar {
        width: 12px;
        background-color: #F0EDED;
    }

    .scrollbar-design::-webkit-scrollbar-thumb {
        border-radius: 10px;
        width: 8px;
        background-color: #DDDADA;
    }
}

.latest-order-card-table {
    // margin-top: 30px;
    padding: 15px;

    .table-title {
        font-size: 25px;
        font-weight: 700;
        color: #3F3E3E;
        font-family: Poppins;
        margin-bottom: 30px;
    }

}

//See all modal
.modal-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    width: 35%;

    .modal-header{
        border-bottom: 1px solid #D8D4D4;
        padding: 11px;
        margin: 0 20px;

        h3{
            width: 50%;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            color: #3C3C3D;
            
        }
    }

    .modal-list {
        display: flex;
        padding: 11px;
        border-bottom: 1px solid #D8D4D4;
        margin: 0px 20px;

        .list-name {
            color: #10CDF2;
            width: 50%;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
        }

        .list-count {
            width: 50%;
            text-align: right;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #3F3E3E;
        }
    }
}


@media(min-width:320px) and (max-width:767px) {
    .desktop-table-block {
        display: none;
    }

    .mobile-table-block {
        display: block;
    }
}

@media(min-width:768px) and (max-width:991px) {
    .desktop-table-block {
        display: block;
    }

    .mobile-table-block {
        display: none;
    }
}

@media(min-width:992px) and (max-width:1023px) {
    .desktop-table-block {
        display: block;
    }

    .mobile-table-block {
        display: none;
    }
}

@media(min-width:1024px) and (max-width:1199px) {
    .desktop-table-block {
        display: block;
    }

    .mobile-table-block {
        display: none;
    }
}

@media(min-width:1200px) {
    .desktop-table-block {
        display: block;
    }

    .mobile-table-block {
        display: none;
    }
}