.settings {

    .main-container {
        .grid-wrapper {
            .title {
                h5 {
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 36px;
                    letter-spacing: 0em;
                    // text-align: left;
                    color: #05A3C1;
                }
            }

            .data-container {
                .data-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 15px 20px;
                    border: 1px solid #E8E4E4;
                    gap: 10px;

                    &:nth-child(odd) {
                        background-color: #FCFAFA;
                    }

                    &:nth-child(even) {
                        background-color: #fff;
                    }

                    .data {
                        width: 80%;

                        h5 {
                            font-family: Poppins;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0em;
                            // text-align: left;
                            color: #000000;
                        }

                        h6 {
                            font-family: Poppins;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 26px;
                            letter-spacing: 0em;
                            // text-align: left;
                            color: #353434;
                        }
                    }

                    .btn-head {
                        width: 20%;
                        // text-align: right;

                        .btn {
                            border: 1px solid #C7C4C4;
                            background-color: #EFF0F3;
                            color: #000000;
                            width: 70%;

                            h6 {
                                font-family: Poppins;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 26px;
                                letter-spacing: 0em;
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

//edit-name
.change-name {
    .settings-header {
        h5 {
            font-family: Poppins;
            font-size: 22px;
            font-weight: 700;
            line-height: 45px;
            letter-spacing: 0em;
            color: #3F3E3E;
        }
    }

    .divider {
        border: 1px solid #D2CDCD;
        width: 100%;
    }

    .form-details{
        padding: 16px;
        margin-bottom: 40px;
        margin-top: 0px;
        .title {
            h6 {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                color: #4D4F4F;
            }
        }
        .name {
            display: flex;
            flex-direction: column;
            gap: 10px;
            // margin-bottom: 20px;

            label {
                font-family: Poppins;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
            }

            .input-field {
                width: 100%;
            }
        }
    }

    .btn-grid {
        display: flex;
        align-items: center;
        gap: 25px;
        .btn {
            background-color: #05A3C1;
            color: #fff;
            border-radius: unset;

            h6 {
                font-family: Poppins;
                font-size: 17px;
                font-weight: 500;
                line-height: 26px;
                letter-spacing: 0em;
                text-transform: capitalize;
            }
        }
        .btn-back{
            background-color: #575555;
        }
    }
}

//edit-email
.change-email {
    .current-email {
        h6 {
            font-weight: 600;

            span {
                font-weight: 400;
                margin-left: 10px;
            }
        }
    }

    .text-title {
        h6 {
            span {
                font-weight: 600;
            }
        }
    }
}

//edit bank detail
.bank-details{
    .form-details{
        padding: 16px;
        margin-bottom: 40px;
        margin-top: 0px;
    }
}

//edit pickup
.pick-up-location {
    .form-details{
        padding: 16px;
        margin-bottom: 40px;
        margin-top: 0px;
        .name {
            .select-form {
                .MuiInputBase-root {
                    background-color: #fff;
                }

                .MuiSelect-select {
                    color: #353434;
                    font-family: Poppins;
                    font-size: 17px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0em;
                    // text-align: left;

                    padding: 14px;
                }
            }
        }
    }
}

.change-password{
    .password-error-text{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
    }
}