.no-product-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40vh;
    color: #333;
  
    .message {
      font-size: 25px;
      font-weight: 600;
      margin: 10px 0;
      font-family: Poppins;
    }
  
    .description {
      font-size: 16px;
      color: #666;
      margin-top: 5px;
      font-family: Poppins;
    }
  
    @media (max-width: 768px) {
      .message {
        font-size: 1.5rem;
      }
  
      .description {
        font-size: 0.9rem;
      }
    }
  
    @media (max-width: 480px) {
      .message {
        font-size: 1.2rem;
      }
  
      .description {
        font-size: 0.8rem;
      }
    }
  }
  