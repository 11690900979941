// primary btn
$btn_primary_color: #3980c0;
$btn_primary_hover_color: rgba(201, 146, 174, 0.1);

// secondary btn
$btn_secondary_color: rgba(201, 146, 174, 0.1);
$btn_secondary_hover_color: #3980c0;

// light btn
$btn_light_color: #3980c0;
$btn_light_background_color: #F6F8FC;
$btn_light_border_color: #B6C8D6;
$btn_light_hover_color: #ffffff;

// link btn
$btn-link_color: $bodycolor;
$btn-link_hover_color: $bodyhovercolor;


.btn {
  font-family: $font_family_body;
  padding: 14px;
  font-weight: 600;
  font-size: $font_small;
  line-height: 20px;
  border-radius: 8px;
  color: $bodycolor;
  min-width: 100px;
  text-transform: capitalize;
}

.primary_btn {
  display: flex;
  flex-wrap: wrap;
  button,
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-child{
        margin-left: 15px;
    }
  }
  .btn.btn-primary {
    background-color: $btn_primary_color;
    border: 1px solid $btn_primary_color;
    color: $white_color;
    margin: 0 0 0 15px;
    &:first-child{
      margin: 0; 
    }
    .btn_svg {
       display: flex;
       align-items: center;
       justify-content: center;
       height: 20px;
       width: 20px;
       margin-right: 10px;
       svg {
        fill:$white_color;
       }
    }
    &:hover {
      background-color: $btn_primary_hover_color;
      border: 1px solid $btn_primary_hover_color;
      color: $btn_primary_color;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        svg {
         fill:$btn_primary_color;
        }
     }
    }
  }

  .btn-transparent {
    background: #FFFFFF;
    border: 1px solid rgba(14, 59, 89, 0.20);
    transition: color .15s ease-in-out;
    &:hover{
      background-color: $btn_light_color;
      border: 1px solid $btn_light_color;
      color: $btn_light_hover_color;
      transition: color .15s ease-in-out;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: color .15s ease-in-out;
        svg {
         fill:$white_color;
         transition: color .15s ease-in-out;
        }
     }
    
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transition: color .15s ease-in-out;
      svg {
        fill: rgba(14, 59, 89, 0.7);
        transition: color .15s ease-in-out;
      }
   }

  }

}

.btn.btn-primary{
  background-color: $btn_primary_color;
  border: 1px solid $btn_primary_color;
  color: $white_color;
  &:hover{
    background-color: $btn_primary_hover_color;
    border: 1px solid $btn_secondary_color;
    color: $btn_primary_color;
  }
}

.btn-secondary{
    background-color: $btn_primary_hover_color;
    border: 1px solid $btn_primary_hover_color;
    color: $btn_primary_color;
    &:hover{
      background-color: $btn_primary_color;
      border: 1px solid $btn_primary_color;
      color: $bodycolor;
    }
}

.btn-light{
  background-color: $btn_light_background_color;
    border: 1px solid $btn_light_border_color;
    color: $btn_light_color;
    &:hover{
      background-color: $btn_light_color;
      border: 1px solid $btn_light_color;
      color: $btn_light_hover_color;
    }
}
.btn-link{
    color: $btn_link_color;
    text-decoration: none;
    &:hover{
      color: $btn_link_hover_color;
    }
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn{
    background-color: $btn_primary_hover_color;
    border: 1px solid $btn_primary_hover_color;
    color: $bodyopacitycolor;
}