// .main {
//     display: flex;
//     margin: 0px;
//     padding: 0px;
//     position: relative;
//     .main-sidebar{
//         position: relative;
//         .sidebar-wrapper{
//             position: sticky;
//             top: 0;
//             left: 0;
//             width: 256px;
//             height: 100%;
//         }
//     }

//     .main-content {
//         .top-bar {
//             width: 100%;
//             background-color: #FAF9F9;
//             //topbar css start
//             .main-topbar {
//                 display: flex;
//                 flex-direction: row;
//                 justify-content: space-between;
//                 align-items: center;
//                 background-color: #FAF9F9;
//                 padding: 15px;
//                 width: 100%;
//                 .topbar-left {
//                     .topbar-btn {
//                         width: 270px;
//                         height: 65px;
//                         background-color: #3F3E3E;
//                         border-radius: unset;
//                     }

//                     .topbar-btn-text {
//                         font-family: Poppins;
//                         font-size: 25px;
//                         font-weight: 700;
//                         line-height: 38px;
//                         letter-spacing: 0em;
//                         color: #FFFFFF;
//                         text-transform: capitalize;
//                     }
//                 }

//                 .topbar-right {
//                     .right-sidebar {

//                         display: flex;
//                         flex-direction: row;
//                         gap: 17px;
//                         justify-content: flex-end;

//                         .icons {
//                             width: 52px;
//                             height: 52px;
//                             line-height: 52px;
//                             text-align: center;
//                             border: 1px solid #9A9797;
//                             border-radius: 50%;
//                             position: relative;
//                             display: flex;
//                             justify-content: center;
//                             align-items: center;

//                             span {
//                                 width: 14px;
//                                 height: 14px;
//                                 line-height: 14px;
//                                 text-align: center;
//                                 background-color: #F40B0B;
//                                 font-family: Poppins;
//                                 font-size: 9px;
//                                 font-weight: 700;
//                                 letter-spacing: 0em;
//                                 color: #fff;
//                                 display: inline-block;
//                                 border-radius: 50%;
//                                 position: absolute;
//                                 top: 12px;
//                                 right: 10px;
//                             }

//                             svg {
//                                 width: 30px;
//                                 height: 30px;
//                             }

//                         }
//                     }
//                 }

//             }
//         }
//     }

// }

// @media (min-width:320px) and (max-width:767px){
//     .main .main-content .top-bar .main-topbar .topbar-left .topbar-btn-text{
//         font-size: 16px;
//         line-height: 20px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-left .topbar-btn{
//         width: 150px;
//         height: auto;
//         padding: 15px 15px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-right .right-sidebar .icons span{
//         top: -3px;
//         right: -2px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-right .right-sidebar .icons{
//         width: 30px;
//         height: 30px;
//     }
//     .main-sidebar{
//         display: none;
//     }
// }
// @media (min-width:768px) and (max-width:991px){
//     .main .main-content .top-bar .main-topbar .topbar-left .topbar-btn-text{
//         font-size: 16px;
//         line-height: 20px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-left .topbar-btn{
//         width: 150px;
//         height: auto;
//         padding: 15px 15px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-right .right-sidebar .icons span{
//         top: -3px;
//         right: -2px;
//     }
//     .main .main-content .top-bar .main-topbar .topbar-right .right-sidebar .icons{
//         width: 30px;
//         height: 30px;
//     }
// }
// @media (min-width:992px) and (max-width:1023px){

// }
// @media (min-width:1024px) and (max-width:1199px){

// }
// @media (min-width:1200px){

// }

//
@import "../../assets/css/variable/variable";

.main_block {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  .main_section {
    margin-left: 250px;
    width: 100%;
    overflow: hidden;
  }
}
.dashboard_main_content {
  padding: 100px 20px;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}



@media (max-width: 767px) {
  .page-details{
    .main{
      .deskPagination {
        display:none;
      }
    }
  }
}

body.ar {
  .directionrtl {
    direction: rtl !important;
    text-align: right;
  }

  .foooter-link a:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #fff;
    opacity: 0.4;
  }

  .foooter-link a:first-child {
    &::after {
      display: none;
    }
  }

  .foooter-link a:first-child:before {
    display: none;
    padding-right: 0;
  }

  .MuiPagination-root .MuiPagination-ul li .MuiPaginationItem-previousNext {
    rotate: 360deg;
}

  .support-text h6 {
    text-align: left;
  }

  .MuiSelect-select {
    text-align: right;
  }

  .MuiMenuItem-root {
    text-align: right;
    justify-content: flex-end;
  }

  .pick-up-location .form-details .name .select-form .MuiSelect-select {
    text-align:right;
    padding-right: 38px;
  }

  .rtl-tabs .MuiTabs-scrollButtons {
    transform: rotate(180deg);
  }

  .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon {
    transform: rotate(180deg);
  }

  .offcanvas {
    visibility: visible;
    &.offcanvas-start {
      left: 100%;
      width: 250px;
      z-index: 99999;
    }
  }
  .header {
    right: 250px;
    left: 0;
  }
  .main_block .main_section {
    margin-right: 250px;
    margin-left: 0px;
  }
  .bulk-upload,
  .bulk-price {
    .main {
      .wrapper {
        .list-text {
          width: 50%;

          .text {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 600;
            text-align: right;
            line-height: 38px;
            letter-spacing: 0em;
            color: #181818;
          }
        }
      }
    }
  }
  .sidebar_main {
    .offcanvas {
      .offcanvas-body {
        .inner_sidebar {
          .menu_list {
            nav {
              .MuiListItem-root {
                .MuiButtonBase-root {
                  .menu-name {
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .page-details {
    .top-card-wrapper {
      .card-bg {
        .bottom {
          .top-selling-list {
            .MuiTypography-root {
              text-align: left;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1023px) {
    .sidebar_main {
      .offcanvas {
        .offcanvas-start {
          right: 0px;
          z-index: 9999;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .main_block .sidebar_section {
    width: auto;
  }
  .main_block .main_section {
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .desktop-table-block {
    display: none;
  }
  .mobile-table-block {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .desktop-table-block {
    display: block;
  }
  .mobile-table-block {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1023px) {
  .desktop-table-block {
    display: block;
  }
  .mobile-table-block {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .desktop-table-block {
    display: block;
  }
  .mobile-table-block {
    display: none;
  }
}
@media (min-width: 1200px) {
  .desktop-table-block {
    display: block;
  }
  .mobile-table-block {
    display: none;
  }
}

@media (max-width: 991px) {
  body.ar .offcanvas.offcanvas-start {
    right: -100%;
    left: unset;
    transform: translateX(100%);
  }
  body.ar .offcanvas.offcanvas-start.show {
    right: 0px;
    left: unset;
    transform: translateX(0%);
  }
  body.ar .main_block .main_section {
    right: 0px;
    margin-right: 0px;
  }
  body.ar .header {
    right: 0px;
  }
}
