

.custom-btn-0 {
    background-color: #10CDF2;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    font-family: Poppins;
    box-shadow: none;
    border-radius: 0px;
    text-transform: capitalize;
}

.custom-btn-1 {
    background-color: #B2B6B7;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    font-size: 13px;
    font-weight: 500;
    font-family: Poppins;
    box-shadow: none;
    border-radius: 0px;
}

.mobile-table-block{
    .MuiTable-root{
        .MuiTableBody-root{
            .MuiTableRow-root{
                &:nth-child(1) td{
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                    font-family: Poppins;
                }
                &:nth-child(2) td{
                    font-size: 14px;
                    font-weight: 500;
                    color: #000;
                    font-family: Poppins;
                }
                &:nth-child(3) td{
                    font-size: 14px;
                    font-weight: 500;
                    color: #10CDF2;
                    font-family: Poppins;
                }
                &:nth-child(7) td{
                    font-size: 14px;
                    font-weight: 500;
                }
                th{
                    border-right: 1px solid #B2B6B7;
                    width: 30%;
                    font-weight: 600;

                }
                td{
                   
                    width: 70%;
                    .custom-btn-0 {
                        background-color: #10CDF2;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        box-shadow: none;
                        border-radius: 0px;
                        text-transform: capitalize;
                    }
                    
                    .custom-btn-1 {
                        background-color: #B2B6B7;
                        color: #fff;
                        text-align: center;
                        white-space: nowrap;
                        font-size: 13px;
                        font-weight: 500;
                        font-family: Poppins;
                        box-shadow: none;
                        border-radius: 0px;
                    }
                    
                }
                
            }
        }
    }
}


